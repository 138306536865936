import {disablePageScroll, enablePageScroll} from 'scroll-lock';
import $ from 'jquery'
import {heightWithTabBar} from "../utils/load";

const initModal = () => {

    const modalFadeSpeed = 300
    const modalContainer = document.getElementById('#modal__container');

    const closeModal = () => {
        $('#modal__outer__container').fadeOut(modalFadeSpeed, () => {
            enablePageScroll(modalContainer)
        })
    }

    $('#modal__center').click((e) => {
        if (e.target.id === 'modal__center') {
            closeModal()
        }
    })

    $('#modal__close').click(closeModal)

    $('[data-open-modal]').click(() => {
        disablePageScroll(modalContainer)
        $('#modal__outer__container').fadeIn(modalFadeSpeed, () => {
            calculateNewModalHeightWithForm()
        })
    })
}

export const calculateNewModalHeightWithForm = () => {
    const height = $('#modal__form__container').outerHeight() + $('#modal__heading__container').outerHeight()
    $('#modal__container').css('max-height', `${height}px`)
}

export const calculateNewModalHeightWithSuccessMessage = () => {
    const height = $('#submit__success').outerHeight() + $('#modal__heading__container').outerHeight()
    $('#modal__container').css('max-height', `${height}px`)
}

export default initModal

