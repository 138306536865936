import $ from 'jquery'
import buildUrl from 'build-url';

export const initBlogEntry = () => {

    const hashtags = $('#blog__entry__hashtags').text().trim()
    const heading = $('#blog__entry__heading').text()

    const handleNavbar = () => {
        const navHeight = $('#nav__container').outerHeight()
        $('#blog__entry__main__image').css('transform', `translateY(-${navHeight}px)`)
        $('.nav__link').css('color', 'white')
        $('#nav__logo path, #nav__logo line').css({'fill': 'white', 'stroke': 'white'})
        document.body.style.setProperty('--nav-menu-color', 'white')
    }

    const handleShareContainer = () => {
        const shareContainer = $('#blog__entry__share__container')
        shareContainer.css('bottom', `calc(50% - ${shareContainer.height() / 2}px)`)
    }

    const twitterShare = () => {
        const twitterUrl = buildUrl('https://twitter.com', {
            path: 'intent/tweet',
            queryParams: {
                text: heading,
                url: window.location.href,
                via: 'LimoncelloFresh',
                hashtags: hashtags.replace(/[\#\s]/g, "")
            }
        });

        $('#twitter__share').attr('href', twitterUrl)
    }

    const initFacebookShare = async  () => {
        window.fbAsyncInit = () => {
            FB.init({
                appId            : "2529198814062034",
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v6.0'
            });
        }

        eval(await $.get('https://connect.facebook.net/en_US/sdk.js'))
    }

    const handleFacebookClick = () => {
        $('#facebook__share').click(() => {
            FB.ui({
                method: 'share',
                href: window.location.href,
                hashtag: hashtags,
                quote: heading + '|' + $('#blog__entry__preview__text').text()
            });
        })
    }

    handleNavbar()
    handleShareContainer()
    twitterShare()
    initFacebookShare()
    handleFacebookClick()
}