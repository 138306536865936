// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("turbolinks").start();

// components
import '../components/modal'
import '../components/nav'
import "../components/loading.js.erb"
import "../components/notices"
import "../components/captcha.js.erb"
import "../components/favicon"
import "../components/footer"

// // pages
import "../pages/home/landing"
import "../pages/contact/contact"
import "../pages/event/event"
import "../pages/newsletter/newsletter"
import "../pages/menu/menu"
import "../pages/blog/blog"
import "../pages/blog/blog_entry.js.erb"
import "../pages/catering/catering"

// utils
import "../utils/submit"
import "../utils/validate_input"
import "../utils/constants"
import "../utils/aos"
import "../utils/load"

