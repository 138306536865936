import $ from "jquery";
import initModal from "../../components/modal";
import {handleValidateUi} from "../../utils/validate_input";
import validator from "validator";
import {inputEvent} from "../../utils/constants";
import submitForm from "../../utils/submit";

const initEvent = () => {

    const selectInput = $('#event__type__input')

    const addFormListeners = () => {
        $('#event__name__input').on(inputEvent, (e) => {
            handleValidateUi(!validator.isEmpty(e.target.value), '#event__name__error')
        })

        $('#event__email__input').on(inputEvent, (e) => {
            handleValidateUi(validator.isEmail(e.target.value), '#event__email__error')
        })

        $('#event__message__input').on(inputEvent, (e) => {
            handleValidateUi(!validator.isEmpty(e.target.value), '#event__message__error')
        })
    }

    const setColorToBlack = () => {
        selectInput.css('color', 'black')
    }

    const handleSelectInputChange = () => {
        selectInput.change(setColorToBlack)
    }

    const handlePopulateSelect = () => {
        $('[data-event-type]').click((e) => {
            selectInput.val(e.target.dataset.eventType)
            setColorToBlack()
        })
    }

    const getPostData = () => ({
        name: $('#event__name__input').val(),
        email: $('#event__email__input').val(),
        eventType: selectInput.val(),
        message: $('#event__message__input').val()
    })

    const inputIsValid = () => {
        const data = getPostData()
        return handleValidateUi(!validator.isEmpty(data.name), '#event__name__error') &&
            handleValidateUi(validator.isEmail(data.email), '#event__email__error') &&
            handleValidateUi(!validator.isEmpty(data.message), '#event__message__error') &&
            handleValidateUi(data.eventType, '#event__type__error')
    }

    const handleSubmit = () => {
        $('#modal__form__container').on('submit', (e) => {
            e.preventDefault()
            if (inputIsValid()) {
                submitForm('/events', getPostData())
            }
        })
    }

    addFormListeners()
    handleSelectInputChange()
    handlePopulateSelect()
    handleSubmit()
}

$(document).on('ready turbolinks:load', () => {

    if (window.location.pathname === "/events") {
        initModal()
        initEvent()
    }
});