import $ from 'jquery'

const initFavicon = () => {
    if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        $('#meta__icon__32')[0].href = $('#fav__white__url').text()
    }
}

$(document).on('ready turbolinks:load', () => {
    initFavicon()
})