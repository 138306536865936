import submitForm from "../../utils/submit";
import initModal from "../../components/modal";
import $ from 'jquery'
import {handleValidateUi} from "../../utils/validate_input";
import validator from "validator";
import {inputEvent} from "../../utils/constants";

const initContact = () => {

    const addFormListeners = () => {
        $('#contact__name__input').on(inputEvent, (e) => {
            handleValidateUi(!validator.isEmpty(e.target.value), '#contact__name__error')
        })

        $('#contact__email__input').on(inputEvent, (e) => {
            handleValidateUi(validator.isEmail(e.target.value), '#contact__email__error')
        })

        $('#contact__message__input').on(inputEvent, (e) => {
            handleValidateUi(!validator.isEmpty(e.target.value), '#contact__message__error')
        })
    }

    const getPostData = () => ({
        name: $('#contact__name__input').val(),
        email: $('#contact__email__input').val(),
        message: $('#contact__message__input').val()
    })

    const inputIsValid = () => {
        const data = getPostData()
        return handleValidateUi(!validator.isEmpty(data.name), '#contact__name__error') &&
            handleValidateUi(validator.isEmail(data.email), '#contact__email__error') &&
            handleValidateUi(!validator.isEmpty(data.message), '#contact__message__error')
    }


    const handleSubmit = () => {
        $('#modal__form__container').on('submit', (e) => {
            e.preventDefault()
            if (inputIsValid()) {
                submitForm('/contact', getPostData(), 'contact')
            }
        })
    }

    addFormListeners()
    handleSubmit()
}

$(document).on('ready turbolinks:load', () => {

    if (window.location.pathname === "/contact") {
        initModal()
        initContact()
    }
});
