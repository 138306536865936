import submitForm from "../../utils/submit";
import $ from 'jquery'
import {handleValidateUi} from "../../utils/validate_input";
import validator from "validator";
import {inputEvent} from "../../utils/constants";

const initNewsletter = () => {

    const addFormListeners = () => {
        $('#newsletter__name__input').on(inputEvent, (e) => {
            handleValidateUi(!validator.isEmpty(e.target.value), '#newsletter__name__error')
        })

        $('#newsletter__email__input').on(inputEvent, (e) => {
            handleValidateUi(validator.isEmail(e.target.value), '#newsletter__email__error')
        })
    }

    const getPostData = () => ({
        name: $('#newsletter__name__input').val(),
        email: $('#newsletter__email__input').val(),
    })

    const inputIsValid = () => {
        const data = getPostData()
        return handleValidateUi(!validator.isEmpty(data.name), '#newsletter__name__error') &&
            handleValidateUi(validator.isEmail(data.email), '#newsletter__email__error')
    }

    const handleSubmit = () => {
        $('#newsletter__form').on('submit', (e) => {
            e.preventDefault()
            if (inputIsValid()) {
                submitForm('/newsletter', getPostData(), 'newsletter')
            }
        })
    }

    addFormListeners()
    handleSubmit()
}

$(document).on('ready turbolinks:load', () => {
    initNewsletter()
});
