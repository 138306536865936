import $ from 'jquery'
import anime from "animejs";
import {getHeight} from 'get-height'

const menuInit = () => {

    const init = () => {
        $('.menu__list__container:not(:first-child)').css('display', 'none')
    }

    const handleClick = () => {
        const menuCategoryItem = $('.menu__category__item')

        menuCategoryItem.click(async ({target}) => {
            if (!$(target).hasClass("menu__selected")) {
                const initialSelectedMenuSelector = $(`#${$('.menu__selected[data-menu-name]')[0].dataset.menuName}`)
                const targetId = target.dataset.menuName

                const targetMenu = $(`#${targetId}`)
                let targetHeight = await getHeight(document.getElementById(targetId))

                menuCategoryItem.removeClass('menu__selected')
                target.classList.add('menu__selected')


                anime.timeline({
                    easing: 'linear',
                    delay: 0
                }).add({
                    targets: '.menu__list__container',
                    opacity: 0,
                    duration: 300,
                    complete: () => {
                        initialSelectedMenuSelector.css('display', 'none')
                        targetMenu.css('display', 'block')
                    }
                }).add({
                    targets: '#menu__list__outer__container',
                    height: [`${initialSelectedMenuSelector.height()}px`, `${targetHeight}px`],
                    duration: 700,
                    easing: 'easeInOutQuad',
                    complete: () => {
                        initialSelectedMenuSelector.css('display', 'none')
                        targetMenu.css('display', 'block')
                    }
                }, '+=100')
                    .add({
                        targets: `#${targetId}`,
                        opacity: 1,
                        duration: 300,
                    }, '+=200')
            }
        });
    }

    init()
    handleClick()
}

$(document).on('ready turbolinks:load', () => {
    if (window.location.pathname === "/menu") {
        menuInit()
    }
});