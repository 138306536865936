import $ from 'jquery'
import {disablePageScroll, enablePageScroll} from 'scroll-lock';

const initNavbar = () => {

    const fadeSpeed = 300
    let mobileNavOpen = false

    const openMobileNav = () => {
        disablePageScroll()
        $('#nav__logo__container').fadeOut(fadeSpeed)
        $('#mobile__nav__menu__parent').css('transform', `translate(-15px, 15PX)`)
        $("#mobile__nav__link__outer__container").fadeIn(fadeSpeed)
        document.body.style.setProperty('--nav-menu-color', 'black')

    }

    const closeMobileNav = () => {
        $('#mobile__nav__menu__parent').css('transform', `initial`)
        $('#nav__logo__container').css('display', 'block')

        if (window.location.pathname.includes('/blog/')) {
            document.body.style.setProperty('--nav-menu-color', 'white')
        }

        $("#mobile__nav__link__outer__container").fadeOut(fadeSpeed, () => {
            enablePageScroll()
        })
    }

    const init = () => {
        $('#mobile__nav__menu__parent').click(() => {
            $('#mobile__nav__menu').toggleClass('mobile--nav--animate')
            mobileNavOpen = !mobileNavOpen
            mobileNavOpen ? openMobileNav() : closeMobileNav()
        })
    }

    init()
}

$(document).on('ready turbolinks:load', () => {
    initNavbar()
})

$(document).on('turbolinks:click', () => {
    enablePageScroll()
})
