import $ from 'jquery'
import anime from "animejs";

const initNotice = () => {

    const noticeContainer = $('#notice__outer__container')

    const animeOptions = {
        targets: '#notice__outer__container',
        delay: 500,
        duration: 500,
        easing: 'easeInOutQuad'
    }

    $('#notice__button').click(() => {
        anime({...animeOptions, bottom: -noticeContainer.outerHeight(), delay: 0,});
    })
}

$(document).on('ready turbolinks:load', () => {
    initNotice()
})