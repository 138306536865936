import $ from 'jquery'
import {calculateNewModalHeightWithForm} from "../components/modal";

export const handleValidateUi = (valid, errorID) => {

    let result

    if (valid) {
        $(errorID).css("display", "none")
        result = true
    } else {
        $(errorID).css("display", "block")
        result = false
    }

    calculateNewModalHeightWithForm()
    return result

}


