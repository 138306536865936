import Glide from "@glidejs/glide"
import $ from 'jquery'
import imagesLoaded from 'imagesloaded'

const landingSlider = () => {

    imagesLoaded('#landing__glider', () => {

    const after = getComputedStyle(document.documentElement).getPropertyValue('--arrow-peek')

    const handleNextClick = glide => {
        $("#landing__next__slide__arrow__container").click(() => glide.go('>'))
    }

    const createGlider = () => (
        new Glide('#landing__glider', {
            type: 'slider',
            perView: 1,
            peek: {before: 0, after},
            gap: 10,
            breakpoints: {
                900: {
                    peek: {before: 0, after: 15},
                    gap: 7
                }
            }
        }).mount()
    )

    const glide = createGlider();

    handleNextClick(glide);
    })
}

$(document).on('turbolinks:load', () => {
    if (window.location.pathname === "/") {
        landingSlider()
    }
});
