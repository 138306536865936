import $ from 'jquery'
import {calculateNewModalHeightWithForm, calculateNewModalHeightWithSuccessMessage} from "../components/modal";
import {getCaptchaToken} from "../components/captcha.js.erb";


const handleContactSuccess = () => {
    $('#modal__form__container').css('display', 'none')
    $('#submit__success').css('display', 'block')
    calculateNewModalHeightWithSuccessMessage()
}

const handleNewsletterSuccess = () => {
    $('#newsletter__form').css('display', 'none')
    $('#newsletter__success').css('display', 'block')
}

const handleNewsletterFailure = (error) => {
    const submitError = $('#newsletter__submit__error')
    submitError.css('display', 'block')
    if (error) {
        submitError.text(error)
    }
}

const handleContactFailure = () => {
    $('#contact__submit__error').css('display', 'block')
    calculateNewModalHeightWithForm()
}

const submitForm = async (url, data, type = 'contact') => {

    $('#form__submit__button').prop( "disabled", true );
    const loadingElement = type ==='newsletter' ? $('#newsletter__loading__container') : $('#modal__loading__container')
    loadingElement.css('display', 'block')

    try {
        await $.post({
            url,
            data: {...data, token: await getCaptchaToken()},
            headers: {"X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')},
        })

        if (type === 'contact') {
            handleContactSuccess()
        } else if (type === 'newsletter') {
            handleNewsletterSuccess()
        }

    } catch (e) {
        if (type === 'contact') {
            handleContactFailure()
        } else if (type === 'newsletter') {
            handleNewsletterFailure(e.responseJSON.error)
        }
    } finally {
        loadingElement.css('display', 'none')
    }
}

export default submitForm;