import $ from "jquery"
import anime from "animejs"
import inlineSVG from 'inline-svg'
import {initBlogPageArrow} from "../pages/blog/blog"
import {initBlogEntry} from "../pages/blog/blog_entry.js.erb"
import {disablePageScroll, enablePageScroll} from 'scroll-lock'
import {aosInit} from "../utils/aos";
import urlParse from 'url-parse'
import imagesloaded from 'imagesloaded'

let clicked = false
let pageFadeOutRun = false
const backgroundColors = ["--quay-tan", "--quay-blue", "--quay-green"]
let backgroundColorIndex = 0

const updateBackgroundIndex = () => {
    if (backgroundColorIndex === backgroundColors.length - 1) {
        backgroundColorIndex = 0
    } else {
        backgroundColorIndex += 1
    }
}

const inlineSvgInit = () => {
    inlineSVG.init({
        svgSelector: 'img.svg',
        initClass: 'js-inlinesvg'
    }, () => {
        initAnimation()

        if (window.location.pathname.includes('/blog/')) {
            initBlogEntry()
        }
    })
}

const handleAnimation = () => {
    anime.timeline({
        easing: 'linear',
        loop: false,
        delay: 0,
        duration: 0,
        endDelay: 0,
    }).add({
        targets: '#site__loading__logo',
        opacity: 1,
        duration: 400,
    })
        .add({
        targets: '.site__loading__logo .animate',
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: (el, i) => i * 300,
        duration: 700,
        direction: 'alternate',
        begin: () => {
            $('#site__loading__logo .animate').css('display', 'block')
        }
    }, '+=200').add({
        targets: '#site__loading__container',
        opacity: 0,
        duration: 400,
        complete: () => {
            $('#site__loading__container').css('display', 'none')
            enablePageScroll()
            aosInit()
            pageFadeOutRun = false
            updateBackgroundIndex()
        },
        endDelay: 400,
    }, '+=200')
}

const initAnimation = () => {
    disablePageScroll()
    const imageElement = document.getElementById('site__loading__logo')
    clicked ? handleAnimation() : imagesloaded(imageElement, handleAnimation)
}

const handlePreLoad = async (e) => {
    if (!pageFadeOutRun) {
        e.preventDefault()
        $('#site__loading__container').css({'display': 'block', 'background-color': `var(${backgroundColors[backgroundColorIndex]})`})
        $('#site__loading__logo').css('opacity', 0)
        clicked = true

        await anime({
            targets: '#site__loading__container',
            opacity: 1,
            duration: 400,
            easing: 'linear',
            endDelay: 100
        }).finished

        Turbolinks.visit(e.originalEvent.data.url)
        pageFadeOutRun = true
    }
}


    $(document).on('turbolinks:before-visit', handlePreLoad)


$(document).on('ready turbolinks:load', inlineSvgInit)