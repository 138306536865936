import AOS from 'aos'
import $ from 'jquery'

export const aosInit = () => {
    AOS.init({
        duration: 600,
        easing: 'ease-out-quad',
        delay: 100,
        offset: -50,
        once: true
    })
}

const handleMobileAnimations = () => {
    if (window.matchMedia('(max-width: 75em)').matches) {
        $('[data-aos]').attr("data-aos", "fade")
    }
}

$(document).on('ready turbolinks:load', () => {
    handleMobileAnimations()
});
