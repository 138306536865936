import $ from 'jquery'
import Glide from "@glidejs/glide";
import initModal from "../../components/modal";

const initCatering = () => {

    const initGlider = () => {
        const glide = new Glide('#catering__glider', {
            type: 'carousel',
            perView: 1,
            peek: 250,
            gap: 12,
            breakpoints: {
                1200: {
                    peek: 50,
                    gap: 15
                },
                900: {
                    peek: 15,
                    gap: 7
                }
            }
        }).mount();

        const settings = glide.settings
        const previousArrowContainer = $('#catering__previous__slide__arrow__container')
        const nextArrowContainer = $('#catering__next__slide__arrow__container')
        const offset = settings.peek - settings.gap - previousArrowContainer.outerWidth()

        previousArrowContainer.css('left', `${offset}px`)
        nextArrowContainer.css('right', `${offset}px`)

        previousArrowContainer.click(() => {
            glide.go('<');
        })

        nextArrowContainer.click(() => {
            glide.go('>');
        })
    }

    initGlider()
}

$(document).on('ready turbolinks:load', () => {

    if (window.location.pathname === "/catering") {
        initCatering()
        initModal()
    }
});